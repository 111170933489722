import React from 'react';

const TermsOfService = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>Terms of service content goes here...</p>
    </div>
  );
};

export default TermsOfService;
