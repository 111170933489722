import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>© 2024 By Prayoga. All rights reserved.</p>
      <p>For support, DM us on <a href="https://x.com/byprayoga" target="_blank" rel="noopener noreferrer">Twitter</a>.</p>
    </footer>
  );
};

export default Footer;