import React from 'react';
import ProductCard from '../components/ProductCard';

const HomePage = () => {
  return (
    <div>
      <section className="hero">
        <h1>Welcome to By Prayoga</h1>
        <p>Improving your mental, physical, relational, and financial health.</p>
      </section>
      <section className="products">
        <ProductCard 
          title="CampsiteMonitor.com"
          description="Helps people spend more time outside by getting campsites at sold-out campgrounds."
          link="https://campsitemonitor.com"
        />
        <ProductCard 
          title="DO IT NOW App"
          description="A unique task management app that helps you be mindful and plan every hour, available on Android and iOS."
          link="#"
        />
      </section>
    </div>
  );
};

export default HomePage;
