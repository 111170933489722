import React from "react";

const AccountDeletion = () => {
  return (
    <div>
      <h1>Permanently delete account</h1>
      <p>
        If you want to permanently delete your account, please send an email to:
        contact@byprayoga.com
      </p>
    </div>
  );
};

export default AccountDeletion;
