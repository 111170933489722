import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <img src="/working_logo.png" alt="By Prayoga Logo" style={{ height: '50px' }} />
      <nav>
        <Link to="/">Home</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-service">Terms of Service</Link>
      </nav>
    </header>
  );
};

export default Header;
